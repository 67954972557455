import React, { useState, useEffect } from 'react';
import { 
  Server, 
  Play, 
  CheckCircle, 
  AlertCircle, 
  Copy, 
  Code, 
  X,
  ChevronDown,
  ChevronUp
} from 'lucide-react';
import { supabase } from '../../../lib/supabase';

interface EndpointOption {
  name: string;
  path: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  description: string;
  requiresAuth: boolean;
  hasParams: boolean;
  hasBody: boolean;
  defaultBody?: string;
}

export function ApiEndpointTester() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [token, setToken] = useState<string>('');
  const [selectedEndpoint, setSelectedEndpoint] = useState<EndpointOption | null>(null);
  const [queryParams, setQueryParams] = useState<{ key: string; value: string }[]>([]);
  const [requestBody, setRequestBody] = useState<string>('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [customUrl, setCustomUrl] = useState('');
  const [customMethod, setCustomMethod] = useState<'GET' | 'POST' | 'PUT' | 'DELETE'>('GET');
  const [responseTime, setResponseTime] = useState<number | null>(null);
  const [useCustomToken, setUseCustomToken] = useState(true);

  // Define available endpoints
  const endpoints: EndpointOption[] = [
    {
      name: 'Test API Connection',
      path: '/api-test',
      method: 'GET',
      description: 'Simple test to verify the API is working',
      requiresAuth: false,
      hasParams: false,
      hasBody: false
    },
    {
      name: 'Get Current User',
      path: '/api/users/me',
      method: 'GET',
      description: 'Retrieves the current authenticated user\'s profile',
      requiresAuth: true,
      hasParams: false,
      hasBody: false
    },
    {
      name: 'Get User Entitlements',
      path: '/api/users/me/entitlements',
      method: 'GET',
      description: 'Retrieves all entitlements for the current user',
      requiresAuth: true,
      hasParams: false,
      hasBody: false
    },
    {
      name: 'Redeem Entitlement Code',
      path: '/api/entitlements/redeem',
      method: 'POST',
      description: 'Redeems an entitlement code for the current user',
      requiresAuth: true,
      hasParams: false,
      hasBody: true,
      defaultBody: JSON.stringify({ code: "EXAMPLE123" }, null, 2)
    },
    {
      name: 'Verify Topic Entitlement',
      path: '/api/entitlements/verify',
      method: 'GET',
      description: 'Checks if the user has an entitlement for a specific topic',
      requiresAuth: true,
      hasParams: true,
      hasBody: false
    },
    {
      name: 'Verify Ad-Free Entitlement',
      path: '/api/entitlements/verify-ad-free',
      method: 'GET',
      description: 'Checks if the user has an ad-free entitlement for a specific topic',
      requiresAuth: true,
      hasParams: true,
      hasBody: false
    },
    {
      name: 'Get Subscription Tiers',
      path: '/api/subscriptions',
      method: 'GET',
      description: 'Retrieves all available subscription tiers',
      requiresAuth: false,
      hasParams: false,
      hasBody: false
    },
    {
      name: 'Get User Subscriptions',
      path: '/api/users/me/subscriptions',
      method: 'GET',
      description: 'Retrieves all active subscriptions for the current user',
      requiresAuth: true,
      hasParams: false,
      hasBody: false
    },
    {
      name: 'View API Documentation',
      path: '/api-docs',
      method: 'GET',
      description: 'Retrieves the API documentation in markdown format',
      requiresAuth: false,
      hasParams: false,
      hasBody: false
    }
  ];

  useEffect(() => {
    const getToken = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.access_token) {
        setToken(session.access_token);
        setUseCustomToken(false);
      }
    };
    
    getToken();
  }, []);

  useEffect(() => {
    if (selectedEndpoint?.hasParams) {
      if (selectedEndpoint.path.includes('verify')) {
        setQueryParams([{ key: 'topic_id', value: '' }]);
      } else {
        setQueryParams([{ key: '', value: '' }]);
      }
    } else {
      setQueryParams([]);
    }

    if (selectedEndpoint?.hasBody) {
      setRequestBody(selectedEndpoint.defaultBody || '{}');
    } else {
      setRequestBody('');
    }
  }, [selectedEndpoint]);

  const handleEndpointChange = (endpoint: EndpointOption) => {
    setSelectedEndpoint(endpoint);
    setError(null);
    setResult(null);
    setResponseTime(null);
  };

  const addQueryParam = () => {
    setQueryParams([...queryParams, { key: '', value: '' }]);
  };

  const removeQueryParam = (index: number) => {
    const newParams = [...queryParams];
    newParams.splice(index, 1);
    setQueryParams(newParams);
  };

  const updateQueryParam = (index: number, field: 'key' | 'value', value: string) => {
    const newParams = [...queryParams];
    newParams[index][field] = value;
    setQueryParams(newParams);
  };

  const buildUrl = () => {
    if (showAdvanced && customUrl) {
      return customUrl;
    }

    if (!selectedEndpoint) return '';

    const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
    
    if (!supabaseUrl) {
      throw new Error('VITE_SUPABASE_URL environment variable is not set');
    }

    // Remove any trailing slashes from the Supabase URL
    const baseUrl = supabaseUrl.replace(/\/$/, '');
    
    // Remove leading slash from the endpoint path and ensure it starts with 'functions/v1'
    const path = selectedEndpoint.path.replace(/^\//, '');
    const functionsPath = path.startsWith('functions/v1/') ? path : `functions/v1/${path}`;
    
    // Construct the full URL
    let url = `${baseUrl}/${functionsPath}`;
    
    if (queryParams.length > 0) {
      const validParams = queryParams.filter(p => p.key && p.value);
      if (validParams.length > 0) {
        url += '?' + validParams.map(p => `${encodeURIComponent(p.key)}=${encodeURIComponent(p.value)}`).join('&');
      }
    }
    
    return url;
  };

  const testEndpoint = async () => {
    if (!selectedEndpoint && !showAdvanced) {
      setError('Please select an endpoint to test');
      return;
    }

    setLoading(true);
    setError(null);
    setResult(null);
    setResponseTime(null);
    
    try {
      const url = buildUrl();
      if (!url) {
        throw new Error('Invalid URL');
      }

      const method = showAdvanced ? customMethod : selectedEndpoint!.method;
      const requiresAuth = showAdvanced ? true : selectedEndpoint!.requiresAuth;
      const hasBody = showAdvanced ? customMethod !== 'GET' : selectedEndpoint!.hasBody;

      if (requiresAuth && !token) {
        throw new Error('Authentication token is required but not available. Please log in first.');
      }

      const headers: HeadersInit = {
        'Accept': 'application/json, text/markdown, text/plain',
        'Content-Type': 'application/json',
      };

      if (requiresAuth) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      const requestOptions: RequestInit = {
        method,
        headers,
        credentials: 'include'
      };

      if (hasBody && (method === 'POST' || method === 'PUT')) {
        try {
          JSON.parse(requestBody); // Validate JSON
          requestOptions.body = requestBody;
        } catch (e) {
          throw new Error('Invalid JSON in request body');
        }
      }

      console.log('Making request to:', url);
      console.log('Request options:', {
        ...requestOptions,
        headers: Object.fromEntries(Object.entries(requestOptions.headers || {}))
      });

      const startTime = performance.now();
      const response = await fetch(url, requestOptions);
      const endTime = performance.now();
      setResponseTime(Math.round(endTime - startTime));

      const contentType = response.headers.get('content-type');
      
      let responseData;
      if (contentType?.includes('application/json')) {
        responseData = await response.json();
      } else if (contentType?.includes('text/markdown') || contentType?.includes('text/plain')) {
        responseData = await response.text();
      } else {
        responseData = await response.text();
        try {
          // Try to parse as JSON even if content-type is not set correctly
          responseData = JSON.parse(responseData);
        } catch (e) {
          // Keep as text if parsing fails
        }
      }

      if (!response.ok) {
        throw new Error(typeof responseData === 'object' ? responseData.error : `HTTP Error ${response.status}: ${responseData}`);
      }

      setResult({
        data: responseData,
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries(response.headers.entries()),
        contentType
      });
    } catch (err: any) {
      console.error('API test error:', err);
      // Enhanced error message with more details
      let errorMessage = err.message || 'An unexpected error occurred';
      if (err.name === 'TypeError' && err.message === 'Failed to fetch') {
        errorMessage = 'Failed to connect to the API. Please check:\n' +
          '1. The API server is running\n' +
          '2. The URL is correct\n' +
          '3. There are no CORS issues\n' +
          '4. Your network connection is stable\n' +
          '5. The Supabase URL environment variable is properly set';
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const formatJson = (json: string): string => {
    try {
      return JSON.stringify(JSON.parse(json), null, 2);
    } catch (e) {
      return json;
    }
  };

  const prettyPrintResponse = (data: any, contentType: string | null): string => {
    if (contentType?.includes('application/json') || typeof data === 'object') {
      return JSON.stringify(data, null, 2);
    }
    return data;
  };

  const handleTokenChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setToken(e.target.value);
    setUseCustomToken(true);
  };

  const resetToUserToken = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (session?.access_token) {
      setToken(session.access_token);
      setUseCustomToken(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <div className="flex items-center mb-6">
          <Server className="h-6 w-6 text-blue-600 mr-2" />
          <h2 className="text-2xl font-semibold">API Endpoint Tester</h2>
        </div>

        <div className="space-y-6">
          {/* Endpoint Selection */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select Endpoint
            </label>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              {endpoints.map((endpoint) => (
                <div
                  key={endpoint.path + endpoint.method}
                  className={`border rounded-lg p-3 cursor-pointer transition-colors ${
                    selectedEndpoint?.path === endpoint.path && selectedEndpoint?.method === endpoint.method && !showAdvanced
                      ? 'border-blue-500 bg-blue-50'
                      : 'hover:border-gray-300'
                  }`}
                  onClick={() => handleEndpointChange(endpoint)}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-medium">{endpoint.name}</h3>
                      <div className="flex items-center mt-1">
                        <span className={`text-xs font-mono px-2 py-1 rounded ${
                          endpoint.method === 'GET' ? 'bg-green-100 text-green-800' :
                          endpoint.method === 'POST' ? 'bg-blue-100 text-blue-800' :
                          endpoint.method === 'PUT' ? 'bg-yellow-100 text-yellow-800' :
                          'bg-red-100 text-red-800'
                        }`}>
                          {endpoint.method}
                        </span>
                        <span className="ml-2 text-xs font-mono text-gray-600">{endpoint.path}</span>
                      </div>
                    </div>
                    {endpoint.requiresAuth && (
                      <span className="text-xs bg-gray-100 px-2 py-1 rounded text-gray-600">
                        Auth Required
                      </span>
                    )}
                  </div>
                  <p className="text-sm text-gray-600 mt-2">{endpoint.description}</p>
                </div>
              ))}
              
              {/* Custom endpoint option */}
              <div
                className={`border rounded-lg p-3 cursor-pointer transition-colors ${
                  showAdvanced
                    ? 'border-purple-500 bg-purple-50'
                    : 'hover:border-gray-300'
                }`}
                onClick={() => {
                  setShowAdvanced(!showAdvanced);
                  if (!showAdvanced) {
                    setSelectedEndpoint(null);
                  }
                }}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium">Custom Endpoint</h3>
                    <div className="flex items-center mt-1">
                      <span className="text-xs font-mono px-2 py-1 rounded bg-purple-100 text-purple-800">
                        CUSTOM
                      </span>
                    </div>
                  </div>
                  <span className="text-xs bg-gray-100 px-2 py-1 rounded text-gray-600">
                    Advanced
                  </span>
                </div>
                <p className="text-sm text-gray-600 mt-2">
                  Test a custom endpoint with your own URL and parameters
                </p>
              </div>
            </div>
          </div>

          {/* Advanced Custom Endpoint */}
          {showAdvanced && (
            <div className="border rounded-lg p-4 bg-purple-50">
              <h3 className="font-medium mb-3">Custom Endpoint Configuration</h3>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    URL
                  </label>
                  <input
                    type="text"
                    value={customUrl}
                    onChange={(e) => setCustomUrl(e.target.value)}
                    placeholder="https://example.com/api/endpoint"
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Method
                  </label>
                  <select
                    value={customMethod}
                    onChange={(e) => setCustomMethod(e.target.value as any)}
                    className="w-full px-3 py-2 border rounded-md"
                  >
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                    <option value="PUT">PUT</option>
                    <option value="DELETE">DELETE</option>
                  </select>
                </div>
                
                {customMethod !== 'GET' && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Request Body (JSON)
                    </label>
                    <textarea
                      value={requestBody}
                      onChange={(e) => setRequestBody(e.target.value)}
                      className="w-full px-3 py-2 border rounded-md font-mono text-sm"
                      rows={5}
                      placeholder="{}"
                    />
                  </div>
                )}
                
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Query Parameters
                  </label>
                  {queryParams.map((param, index) => (
                    <div key={index} className="flex gap-2 mb-2">
                      <input
                        type="text"
                        value={param.key}
                        onChange={(e) => updateQueryParam(index, 'key', e.target.value)}
                        placeholder="Parameter name"
                        className="flex-1 px-3 py-2 border rounded-md"
                      />
                      <input
                        type="text"
                        value={param.value}
                        onChange={(e) => updateQueryParam(index, 'value', e.target.value)}
                        placeholder="Value"
                        className="flex-1 px-3 py-2 border rounded-md"
                      />
                      <button
                        onClick={() => removeQueryParam(index)}
                        className="px-3 py-2 text-red-600 hover:text-red-800"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={addQueryParam}
                    className="text-sm text-blue-600 hover:text-blue-800"
                  >
                    + Add Parameter
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Standard Endpoint Configuration */}
          {selectedEndpoint && !showAdvanced && (
            <div className="border rounded-lg p-4">
              <h3 className="font-medium mb-3">Endpoint Configuration</h3>
              
              <div className="mb-4">
                <div className="flex items-center text-sm text-gray-500 mb-1">
                  <Code className="h-4 w-4 mr-1" />
                  Request URL
                </div>
                <div className="bg-gray-50 p-2 rounded font-mono text-sm break-all">
                  {buildUrl()}
                </div>
              </div>
              
              {selectedEndpoint.hasParams && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Query Parameters
                  </label>
                  {queryParams.map((param, index) => (
                    <div key={index} className="flex gap-2 mb-2">
                      <input
                        type="text"
                        value={param.key}
                        onChange={(e) => updateQueryParam(index, 'key', e.target.value)}
                        placeholder="Parameter name"
                        className="flex-1 px-3 py-2 border rounded-md"
                        readOnly={param.key === 'topic_id'}
                      />
                      <input
                        type="text"
                        value={param.value}
                        onChange={(e) => updateQueryParam(index, 'value', e.target.value)}
                        placeholder="Value"
                        className="flex-1 px-3 py-2 border rounded-md"
                      />
                      {index > 0 && (
                        <button
                          onClick={() => removeQueryParam(index)}
                          className="px-3 py-2 text-red-600 hover:text-red-800"
                        >
                          <X className="h-5 w-5" />
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    onClick={addQueryParam}
                    className="text-sm text-blue-600 hover:text-blue-800"
                  >
                    + Add Parameter
                  </button>
                </div>
              )}
              
              {selectedEndpoint.hasBody && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Request Body (JSON)
                  </label>
                  <textarea
                    value={requestBody}
                    onChange={(e) => setRequestBody(e.target.value)}
                    className="w-full px-3 py-2 border rounded-md font-mono text-sm"
                    rows={5}
                  />
                  <div className="flex justify-end mt-1">
                    <button
                      onClick={() => setRequestBody(formatJson(requestBody))}
                      className="text-xs text-blue-600 hover:text-blue-800"
                    >
                      Format JSON
                    </button>
                  </div>
                </div>
              )}
              
              {selectedEndpoint.requiresAuth && (
                <div className="mb-4">
                  <div className="flex items-center justify-between">
                    <label className="block text-sm font-medium text-gray-700">
                      Authentication Token
                    </label>
                    <div className="flex space-x-2">
                      {!useCustomToken && (
                        <span className="text-xs text-green-600">Using your session token</span>
                      )}
                      {useCustomToken && (
                        <button
                          onClick={resetToUserToken}
                          className="text-xs text-blue-600 hover:text-blue-800"
                        >
                          Use my token
                        </button>
                      )}
                      <button
                        onClick={() => copyToClipboard(token)}
                        className="text-xs text-blue-600 hover:text-blue-800 flex items-center"
                      >
                        <Copy className="h-3 w-3 mr-1" />
                        Copy
                      </button>
                    </div>
                  </div>
                  <textarea
                    value={token}
                    onChange={handleTokenChange}
                    className="mt-1 w-full px-3 py-2 border rounded-md font-mono text-xs"
                    rows={3}
                  />
                </div>
              )}
            </div>
          )}

          {/* Test Button */}
          <button
            onClick={testEndpoint}
            disabled={loading || (!selectedEndpoint && !showAdvanced) || (showAdvanced && !customUrl)}
            className={`w-full flex justify-center items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 ${
              (loading || (!selectedEndpoint && !showAdvanced) || (showAdvanced && !customUrl)) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {loading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2" />
                Testing...
              </>
            ) : (
              <>
                <Play className="h-4 w-4 mr-2" />
                Test Endpoint
              </>
            )}
          </button>

          {/* Error Message */}
          {error && (
            <div className="p-4 bg-red-50 text-red-700 rounded-md flex items-center">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <pre className="text-sm whitespace-pre-wrap">{error}</pre>
            </div>
          )}

          {/* Response */}
          {result && (
            <div>
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-lg font-medium">Response</h3>
                <div className="flex items-center">
                  {responseTime !== null && (
                    <span className="text-xs text-gray-500 mr-3">
                      {responseTime}ms
                    </span>
                  )}
                  <button
                    onClick={() => copyToClipboard(prettyPrintResponse(result.data, result.contentType))}
                    className="text-gray-500 hover:text-gray-700"
                    title="Copy to clipboard"
                  >
                    <Copy className="h-4 w-4" />
                  </button>
                </div>
              </div>
              
              <div className="bg-green-50 rounded-md p-4 border border-green-100">
                <div className="flex items-center mb-2">
                  <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-green-700 font-medium">
                    Success! Status: {result.status} {result.statusText}
                  </span>
                </div>
                
                <div className="mb-3">
                  <button
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    className="text-xs text-gray-600 hover:text-gray-800 flex items-center"
                  >
                    {showAdvanced ? (
                      <>
                        <ChevronUp className="h-3 w-3 mr-1" />
                        Hide Headers
                      </>
                    ) : (
                      <>
                        <ChevronDown className="h-3 w-3 mr-1" />
                        Show Headers
                      </>
                    )}
                  </button>
                  
                  {showAdvanced && (
                    <div className="mt-2 bg-white p-2 rounded border border-green-100 text-xs font-mono">
                      {Object.entries(result.headers).map(([key, value]) => (
                        <div key={key} className="mb-1">
                          <span className="text-gray-600">{key}:</span> {value as string}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                
                <pre className={`text-sm font-mono whitespace-pre-wrap bg-white p-3 rounded border border-green-100 overflow-auto max-h-96 ${
                  result.contentType?.includes('text/markdown') ? 'prose prose-sm max-w-none' : ''
                }`}>
                  {prettyPrintResponse(result.data, result.contentType)}
                </pre>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}